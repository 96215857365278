import { formatPrice } from './priceValueFormat.util';

export function formatQuantity(
  quantity: number,
  precision = 0,
  sign = ' ',
  rounded = true,
  hideSign = false,
): string {
  return formatPrice(quantity, undefined, precision, sign, rounded, hideSign);
}
